import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import NavButtons from 'components/Software/Windows/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Windows Software for your INSTAR IP Camera",
  "path": "/Software/Windows/P2P_Client/",
  "dateChanged": "2017-12-05",
  "author": "Mike Polinowski",
  "excerpt": "The Windows app P2P Client for your PC or Laptop. Control your camera from your computer and harness the computing power for advanced features.",
  "image": "./P_SearchThumb_P2P_Client.png",
  "social": "/images/Search/P_SearchThumb_P2P_Client.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Windows-P2P_Client_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='P2P Client' dateChanged='2017-12-05' author='Mike Polinowski' tag='INSTAR IP Camera' description='The Windows app P2P Client for your PC or Laptop. Control your camera from your computer and harness the computing power for advanced features.' image='/images/Search/P_SearchThumb_P2P_Client.png' twitter='/images/Search/P_SearchThumb_P2P_Client.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Windows/P2P_Client/' locationFR='/fr/Software/Windows/P2P_Client/' crumbLabel="P2P Client" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "windows",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#windows",
        "aria-label": "windows permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Windows`}</h2>
    <h3 {...{
      "id": "p2p-client",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#p2p-client",
        "aria-label": "p2p client permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`P2P Client`}</h3>
    <p>{`The Point-2-Point protocol integrated in our HD camera line (not available for VGA models) allows you to connect to your camera from the internet without the need for a port forwarding rule inside your local router. The advantage of the P2P technology is that you can use it in places where traditional DDNS fails - like IPv6 DSLite tunnels and mobile (3G/LTE) routers without static WAN IP addresses. You can find Installation Guides to use the P2P connection with our mobile apps like our `}<a parentName="p" {...{
        "href": "/en/Software/iOS/InstarVision/"
      }}>{`InstarVision for iPhone`}</a></p>
    <p>{`In order to integrate your INSTAR HD IP camera in the P2P Client for Windows we recommend you to download the latest version from `}<a href="https://wiki.instar.com/dl/P2P_Client.zip">{`here`}</a>{`.`}</p>
    <p><strong parentName="p">{`Remark`}</strong>{`: The P2P Access using third-party software like the P2P Client is only possible with our 720p models (`}<a parentName="p" {...{
        "href": "/en/Outdoor_Cameras/IN-5905_HD/"
      }}>{`IN-5905 HD`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Outdoor_Cameras/IN-5907_HD/"
      }}>{`IN-5907 HD`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Outdoor_Cameras/IN-6001_HD/"
      }}>{`IN-6001 HD`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Outdoor_Cameras/IN-6012_HD/"
      }}>{`IN-6012 HD`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Outdoor_Cameras/IN-6014_HD/"
      }}>{`IN-6014 HD`}</a>{` and `}<a parentName="p" {...{
        "href": "/en/Outdoor_Cameras/IN-7011_HD/"
      }}>{`IN-7011 HD`}</a>{`). All INSTAR Full HD Cameras use a much improved P2P version that is not supported by third-party software. Accessing `}<strong parentName="p">{`Full HD Cameras`}</strong>{` over P2P is only possible with our `}<strong parentName="p">{`free InstarVision Apps`}</strong>{` for `}<a parentName="p" {...{
        "href": "/en/Software/Android/InstarVision/P2P/"
      }}>{`Android`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Software/iOS/InstarVision/iPhone/P2P/"
      }}>{`iPhone`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Software/iOS/InstarVision/iPad/P2P/"
      }}>{`iPad`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Software/Windows/InstarVision/Windows_Phone/P2P/"
      }}>{`Windows Phone`}</a>{` and `}<a parentName="p" {...{
        "href": "/en/Software/Windows/InstarVision/Metro/P2P/"
      }}>{`Windows Metro`}</a>{`.`}</p>
    <h3 {...{
      "id": "add-a-p2p-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#add-a-p2p-camera",
        "aria-label": "add a p2p camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add a P2P Camera`}</h3>
    <p>{`Start the software and click on the cog symbol to - log in with `}<strong parentName="p">{`admin`}</strong>{` and `}<strong parentName="p">{`NO PASSWORD`}</strong>{` - and open the camera management window.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/69d2a3bea488feee3f74e1256597b3a5/fbf08/P2P-Client_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACk0lEQVQoz42S/08ScRzGP3fAAYGhiZEpIHB3fAtFiCPwVHaCinyH48sJmoYVMlJUQMAvtdlmbWaKHlKr/7UBZk5r69nzw2fP9tr72bMPmAqkQ0t5Olv0p3NGVzi1eRzMFCg65V1cp19XHL6U3b9ERgpah1dPBgykz0D6jKSPmI1ZqBAgKH+YWV3NF1dymxjhflP+lFirBJMZenkjkz3yBDOU/6VtYV1ln8IIj87m0ltJtYkw2V1a6wTw5j+ENj9TqwdzuSMXs9WPoxL5SJ9M0/tEJZEppcPo4BD2eETdp1MNyLUDClwqxwbkuEyBDqoNILJzlqh/j9dbif2fs2vvERgBbUHgt2AA8yAE3BOPLwDhrdNIhQ2XGrFay/PqgM8RtlEIhq4FODCnHUIAuiUAAF8ouoEvujAC8//7svAOfPiwVyoQ9whFvUKRpGux6NFDiRSRiAViyR+Lenr6ZbfgasudPXxqNsl1ZgVqVaAWBWpRohY1ah1+NiYzG4aw8SHc3LVcO6Y0WEG4+CVSvoyUGtFKM5D/yOXdawhBEIdzvzYXEYBo6TxW+xbbvaL3fgQLx4igOxins1nbMMzlIggEgZsEguDrwSbo9YlEYTq1MckUx71pmMvrzAXdOgxzOuEdIYIHQGOZRG0zmucuzO5RmBwwh/uX1v+CMRulnwzgjnnjdEg1PoXZPUbSN+rwoMSMctQ5bLDhdrdljtFYXbhjvvOxF3ROr570ax1zACco1O7WOWfxFx6MmIltn8TLjZWDFlNj6Z2v9M5pus4y9Wa8fE6XzhKVxvJBK15uxCuN6PYJcISzzliOYt45o28J/0pqt8HUrxLVy2SVXdxrZvZbySrL1NjM/lWqxqZqzWSV7T6Y3YtfSVauEzC0cf4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/69d2a3bea488feee3f74e1256597b3a5/e4706/P2P-Client_01.avif 230w", "/en/static/69d2a3bea488feee3f74e1256597b3a5/d1af7/P2P-Client_01.avif 460w", "/en/static/69d2a3bea488feee3f74e1256597b3a5/7f308/P2P-Client_01.avif 920w", "/en/static/69d2a3bea488feee3f74e1256597b3a5/4346e/P2P-Client_01.avif 962w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/69d2a3bea488feee3f74e1256597b3a5/a0b58/P2P-Client_01.webp 230w", "/en/static/69d2a3bea488feee3f74e1256597b3a5/bc10c/P2P-Client_01.webp 460w", "/en/static/69d2a3bea488feee3f74e1256597b3a5/966d8/P2P-Client_01.webp 920w", "/en/static/69d2a3bea488feee3f74e1256597b3a5/c76c7/P2P-Client_01.webp 962w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/69d2a3bea488feee3f74e1256597b3a5/81c8e/P2P-Client_01.png 230w", "/en/static/69d2a3bea488feee3f74e1256597b3a5/08a84/P2P-Client_01.png 460w", "/en/static/69d2a3bea488feee3f74e1256597b3a5/c0255/P2P-Client_01.png 920w", "/en/static/69d2a3bea488feee3f74e1256597b3a5/fbf08/P2P-Client_01.png 962w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/69d2a3bea488feee3f74e1256597b3a5/c0255/P2P-Client_01.png",
              "alt": "P2P Client for your INSTAR IP Camera",
              "title": "P2P Client for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on the cog wheel symbol to open the camera management.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2526e867bf2142f5285bc2d3f447557b/e4a12/P2P-Client_01b.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAC00lEQVQoz22S2U8aURTG77CLIBRQEAQVHAYBBUFGWQYtm0oH2dehiguGpVqliECxmpg0NqmNoiC2fe5zX/vfNdC0oaVfvof7cH/nnPudCzAf4UvnQztHL4icGvO5Y1mXP+XNFOKlD5GD6rI3voS/tAaKiHljFsPVPc/ZcdQTMTgDAHXggWRmt3C8UywpDPYgkcVje0S+nDv/vH1w7fERTl/GtJGfRu0zqEtlWp01WuVadA61qxZtwFu89L++duw01nJXq6mSQKngyqZ4IpgnhrmiqVEpPD6hFMoVPLV8TKYakyGjUuWYDBFNwmKFBoTKd9H6U6zeiTW+rmUvaWQ66AoCv0UCJCpEAwOi0hnAf3ITPG0Fys1wrePZu6BTmIMwDaIBCEB9AgDQh4ZB4ORTD77vwe9oZAYAgMUTskfFTJ6QzhGw+WKBQNZX7U/noT642vHsX4xwBQz2iHwBg1GXbmXTEdrWOQJCvY3K5VCZI/ThEQaL0/Uwm80X/gW79y8k+rkJRDc9b5vQon4i9+37j93tnFuu1hn1WpVRpTJIlHqJUi9FdJNqIwh03/wQLDdDlfZm8T2V1g1MMru4FtnaffXmuHFVKJScyAKb1E2S2jc2hcboph2uPoXPHiP1L5uH1/ShbmB0FkcgEvOF42wunyeUUJ4JAAT9MgSRIIjUC4wFbJG8NVq0Jw6xxJFhPU2m/rsVCILIFMrgqmgMJpgx2BUmp8K4CqOuyXkzaeAeBJHIFOr/YSXqVNlwpXlds+KfXsDgJbfWjuusHnjZPaWzSjUmZNlt2CAUi88Rq1eD4RoMV1m9asyHWNYBgjq7n9bsUS55YJMzfPIxWrnPvOukau1I+TZaviXetlP1dqzSjJTvYpXm1nknWmlGT+/DpRtgCWYt4ZwzeWgJ51A8kzi7S9YfY9WHeLWVqrfTjU682krWWunGY6LWStRa8epD79BOnDV/Aj4BvZt94RLvAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2526e867bf2142f5285bc2d3f447557b/e4706/P2P-Client_01b.avif 230w", "/en/static/2526e867bf2142f5285bc2d3f447557b/d1af7/P2P-Client_01b.avif 460w", "/en/static/2526e867bf2142f5285bc2d3f447557b/7f308/P2P-Client_01b.avif 920w", "/en/static/2526e867bf2142f5285bc2d3f447557b/43a10/P2P-Client_01b.avif 963w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2526e867bf2142f5285bc2d3f447557b/a0b58/P2P-Client_01b.webp 230w", "/en/static/2526e867bf2142f5285bc2d3f447557b/bc10c/P2P-Client_01b.webp 460w", "/en/static/2526e867bf2142f5285bc2d3f447557b/966d8/P2P-Client_01b.webp 920w", "/en/static/2526e867bf2142f5285bc2d3f447557b/095e2/P2P-Client_01b.webp 963w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2526e867bf2142f5285bc2d3f447557b/81c8e/P2P-Client_01b.png 230w", "/en/static/2526e867bf2142f5285bc2d3f447557b/08a84/P2P-Client_01b.png 460w", "/en/static/2526e867bf2142f5285bc2d3f447557b/c0255/P2P-Client_01b.png 920w", "/en/static/2526e867bf2142f5285bc2d3f447557b/e4a12/P2P-Client_01b.png 963w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2526e867bf2142f5285bc2d3f447557b/c0255/P2P-Client_01b.png",
              "alt": "P2P Client for your INSTAR IP Camera",
              "title": "P2P Client for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Log in with `}<strong parentName="p">{`admin`}</strong>{` and `}<strong parentName="p">{`NO PASSWORD`}</strong>{`.`}</p>
    <p>{`Open the Add Area dialogue to add camera groups for your different surveillance sites.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d7344e8c924688c5e998ea38298b1d71/d9199/P2P-Client_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACZUlEQVQoz5WSa0/aABSG+0cm2ChmXqhQoBuFFoRSbUuhF2JFy6VaICBSENoCIupM5kiUhSIw3Y9dwC9bJFuWnE9vzpP3vOccAE/IrFwWlFpcrmxhLCkVo7x8kis3tIdssfuZOUKYrJ+rbqDUZpDdDMa3sbgTZ5GY6IlygDdMJY9ySllLKyXbDkLx6QAtFuT8Xf1ePm59ijJ7ByJK5D9sIzYoADp9G5B3ddO9vuMBt2HAGeYQRvZRx1shLihdUKUvWKZNKNe02g9mjHCmrR8r9/kCpd4w2iNTHdDnA7o6iNcewxkDgElpX64xWQ1NKsRZP9X9KZozoT3jjWmq85pojqR8OVfS6OpgIU7mpT+LnReycAu4CDEkqnupgpdKR5QrwZxxzRHXGnMti9fHbP0JZ6VIMk2fP/DGZKGPuaYlmNOY2gfg/aPYSY3K1pF4Lqr0FrD11sTrz4z2lCs3Ko32HNbfwQ5fxIEQH/3kmmcvunBO/gbHtSdGUoRMgVrqvObGViC/DfKvOP3vYbY+dPgi654QVXngjSnXsv6EYdy2G7DvBmwQutT5UDnPlLTlmf8FD9ULXTN6dPXbksx/h9n6EMIoCDtYjD35v7ETje92N74CoVTl69LMmA1C7RBqmy+sKxjT5OVofq2mxbfGifoQhEOrruAc1ifzF2ha3OVIMCaxsz7g8IZBGAdhfNWFvX0Yb0wFcyaYM7H9I9m0QDhkdwWZi0Gq8yKYE8GcCsYk1X0li3fADs4iB4e+/UNniENTFVK9IZQr4rRHnPZiZ9eRXAd0Y3ZXICy3yOJtTO0v6pos3gWl2i8lZEoZ7EMYZwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d7344e8c924688c5e998ea38298b1d71/e4706/P2P-Client_02.avif 230w", "/en/static/d7344e8c924688c5e998ea38298b1d71/d1af7/P2P-Client_02.avif 460w", "/en/static/d7344e8c924688c5e998ea38298b1d71/7f308/P2P-Client_02.avif 920w", "/en/static/d7344e8c924688c5e998ea38298b1d71/542bc/P2P-Client_02.avif 960w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d7344e8c924688c5e998ea38298b1d71/a0b58/P2P-Client_02.webp 230w", "/en/static/d7344e8c924688c5e998ea38298b1d71/bc10c/P2P-Client_02.webp 460w", "/en/static/d7344e8c924688c5e998ea38298b1d71/966d8/P2P-Client_02.webp 920w", "/en/static/d7344e8c924688c5e998ea38298b1d71/e46b2/P2P-Client_02.webp 960w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d7344e8c924688c5e998ea38298b1d71/81c8e/P2P-Client_02.png 230w", "/en/static/d7344e8c924688c5e998ea38298b1d71/08a84/P2P-Client_02.png 460w", "/en/static/d7344e8c924688c5e998ea38298b1d71/c0255/P2P-Client_02.png 920w", "/en/static/d7344e8c924688c5e998ea38298b1d71/d9199/P2P-Client_02.png 960w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d7344e8c924688c5e998ea38298b1d71/c0255/P2P-Client_02.png",
              "alt": "P2P Client for your INSTAR IP Camera",
              "title": "P2P Client for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on Add Area to add a new camera group.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fc2b8c0f0993ff32b4e4cbd01b6944ee/fbf08/P2P-Client_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACpElEQVQoz43Sa0/aYBgG4P6PRRFw6KKcsVDOtYVCW0pPgEgtUjkLyARpoUyGcwclcQk6QGX/dQFjopsfltzfnlx53vfOA3iINLFfpLO1aLr0HoqiyTxCpfFcNXV6nSh2QSzhiGScVFUHhg0QboCiG+7oBw9uRxgLTAHgLkGnc1K1uS9VtCYoymaCJEfnCmLnmhFkdygWiO25ovlVo2vN4tObnZtWp27LtmFx6o0OwAQzTlJ0EsIWzPrTTbzyNZDtoUcDrDj0i0ow28sU6oXaaaR0QTVvyMaIaIzIxjXVvIGzMmDHUuFMHT9oQNRhKH+eUOecMuOUKStPEr1HujVOZMtCoU7UrzhlxnYmrDxhO7/47gNWHAK2cHI3WUZSZZAQEKnPKTPm7I6VJ5w85bszuj0Oc0I0IRK1H6w8Yc7uFmnfcso0XBgANmxvsVk8AUkRlfp8955q3kQrl1jpEs0PvYKMpfKp/Al+/G2Jb19hA4gYnOgmFNY7YCSnJvtzROqvO4JrtsBOiIsfnNDZ2qYrvHvY5bv3dPs1Xrf7V80ejdmtMbmX+DeSU7VWn87mhbBkptxL5hs6qxcWlTdxQGPxrlm8GrPnGfdWtl0RTojwggejkXj63Rb4H1hS+e4D2RgFhDZVGTK1y1jpfE+qEkkxUv7CydO///wSo89t8917pjNh5BnVGpv9uAOOEbWrNwr7Fy/etgx7dhf/+FNj8a0Y3fjxd/atzX7NojCPxuRGJZWTp3Rr/BLr7EGt1bfAnQnTXo5aY06ehPMDwLAD6x1BvT2otflDhUFCnbPydHlks6eGnjDZGPG9R05ZjuRpQp1jpQtg20+CkdQOljQG4xBfDR2dI7keklORnIpKfVhUtFa/xuINZFqhwgA9+rRMP1T47E3V/wA2Xz5P3QiY9wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fc2b8c0f0993ff32b4e4cbd01b6944ee/e4706/P2P-Client_03.avif 230w", "/en/static/fc2b8c0f0993ff32b4e4cbd01b6944ee/d1af7/P2P-Client_03.avif 460w", "/en/static/fc2b8c0f0993ff32b4e4cbd01b6944ee/7f308/P2P-Client_03.avif 920w", "/en/static/fc2b8c0f0993ff32b4e4cbd01b6944ee/4346e/P2P-Client_03.avif 962w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fc2b8c0f0993ff32b4e4cbd01b6944ee/a0b58/P2P-Client_03.webp 230w", "/en/static/fc2b8c0f0993ff32b4e4cbd01b6944ee/bc10c/P2P-Client_03.webp 460w", "/en/static/fc2b8c0f0993ff32b4e4cbd01b6944ee/966d8/P2P-Client_03.webp 920w", "/en/static/fc2b8c0f0993ff32b4e4cbd01b6944ee/c76c7/P2P-Client_03.webp 962w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fc2b8c0f0993ff32b4e4cbd01b6944ee/81c8e/P2P-Client_03.png 230w", "/en/static/fc2b8c0f0993ff32b4e4cbd01b6944ee/08a84/P2P-Client_03.png 460w", "/en/static/fc2b8c0f0993ff32b4e4cbd01b6944ee/c0255/P2P-Client_03.png 920w", "/en/static/fc2b8c0f0993ff32b4e4cbd01b6944ee/fbf08/P2P-Client_03.png 962w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fc2b8c0f0993ff32b4e4cbd01b6944ee/c0255/P2P-Client_03.png",
              "alt": "P2P Client for your INSTAR IP Camera",
              "title": "P2P Client for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Name your camera group.`}</p>
    <p>{`Now open the `}<strong parentName="p">{`Add Device`}</strong>{` dialogue to add your camera. You need to type in your cameras UID (Point-2-Point User Identification) which can be found in the cameras web user interfaces under network / p2p. Please also read the documentation on P2P from the download area linked above. Please make sure to use the password for the `}<strong parentName="p">{`admin account`}</strong>{` of your camera! Name the camera and assign it to the group generated earlier.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/eac247b6f31a58356bdcd45f17ceacd4/6bff2/P2P-Client_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACVUlEQVQoz5XSe0/aYBQG8H6PDQWZmVNuhSHFteXSQQttgbZUQQoqOKDSCyylLYoxcRoHyLYMuaj7sEu3LFk2lsXk+evk/N4nJ3mBaLZClUS6IiV26+4og/HHWLZEVdW8esPU9FC6CFGH4ay4AdNbaG4TzbgiOW+M2cb5IM4D27FUXqg25M5upebwQFleiJG5vWar1hvzB+YORqBUMZis2bYCDt+OYxNcd4POLdD+0mXfBAFvnIXoMkQJr9BMpNTB6xeIoMUPTxO1PipoEUHbr4qVukyKF1x3nFMGqZPrlHhNygOk2Aa20yWirJIH7RBZIU5u+LPHvLngzDlrzPnTR7rzWWi2q20zJQ05857VZ5wxZ/XZbv9b4vgcCBAFbE9MFMUAUcSbHzhzzmhfme7EijHNKMN8IssTHCl9ZI3Zj/ldTpvke/fY0RkQSBawwklyv+XHC0TzijMXjGZtWDFmGXnYa6jnkpZu3SzBG2HchVKeCL0eShBW8yL3C7PGjFRGbKVeeCenWsuaHSD83BWyuaAVN4Q3fscT1phRymjVB9sDUVIeLMHOAGoHEQeI2H0w/kezbuFyQz2StOXNTj9qve2DV71v/sa0eltXNEnrp5+IJz+bg3EaSrKkNHgi/nHzM1fY5oX/cfP/8JoffRGML8drfsTm3lnxWCGaV/neA9OdsvqM1aecuaDVsR1EnK9jlDK0vp1+x+pTpnvHnz68rfYBX5yBaSFMlTwxBjs6o9tjUhqQ8pCUB5Ryizcu7SCyFojijUu684lSR5R6SyqjzPsvkXL3O4mZSw/1QZZbAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/eac247b6f31a58356bdcd45f17ceacd4/e4706/P2P-Client_04.avif 230w", "/en/static/eac247b6f31a58356bdcd45f17ceacd4/d1af7/P2P-Client_04.avif 460w", "/en/static/eac247b6f31a58356bdcd45f17ceacd4/7f308/P2P-Client_04.avif 920w", "/en/static/eac247b6f31a58356bdcd45f17ceacd4/b4975/P2P-Client_04.avif 957w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/eac247b6f31a58356bdcd45f17ceacd4/a0b58/P2P-Client_04.webp 230w", "/en/static/eac247b6f31a58356bdcd45f17ceacd4/bc10c/P2P-Client_04.webp 460w", "/en/static/eac247b6f31a58356bdcd45f17ceacd4/966d8/P2P-Client_04.webp 920w", "/en/static/eac247b6f31a58356bdcd45f17ceacd4/c15ec/P2P-Client_04.webp 957w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/eac247b6f31a58356bdcd45f17ceacd4/81c8e/P2P-Client_04.png 230w", "/en/static/eac247b6f31a58356bdcd45f17ceacd4/08a84/P2P-Client_04.png 460w", "/en/static/eac247b6f31a58356bdcd45f17ceacd4/c0255/P2P-Client_04.png 920w", "/en/static/eac247b6f31a58356bdcd45f17ceacd4/6bff2/P2P-Client_04.png 957w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/eac247b6f31a58356bdcd45f17ceacd4/c0255/P2P-Client_04.png",
              "alt": "P2P Client for your INSTAR IP Camera",
              "title": "P2P Client for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on Add Device to add a new camera.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f7874686aa2cec793d84636acf901482/fbf08/P2P-Client_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAC7UlEQVQoz3XRW0/aYBgHcL7CrpYs0YLOHRyHclKg0hYKpS1i27cVAe2ErmUqMkELbUEHus0ZdsiESdEt2dV2t7t9giW7WLIPtehcdsh2//yew/+xEaDIFTdFpc6uVuE4oAvrtFhkla3lxovc+m6UlRC2hIlbTmzBHeM9MR6OA39CQNL5MJ21BXAGFEpqpSYpG+POGQbkY2nAl5Si3hMlPUww0XQ2mFLGnEG7JzLhCk65/NAt95Qr4Ljjs7lwboZZCdKFaYyfW9pk1g5QSSdKuym1E5U07K5eUKpypZ6pHizqr0D9BVM5YipHmQfP8ZWGLUDlKKk2v1pHODmhdBbbb0VzJJqngmFl2285bZCXK/J6PV3tAX3EN0+AbvHNE7F1SqodG5wQMEGJiWqAyhNyWzAsXhvw2oDd6S9sHzPVZ2gmRwApvXkEmkO+MeAbr3ltIBhWUnlo81P5lFSbL27PLpRIdZ9UO040M42yThz4yBycWJqlCt6EeDPCxFcNwTzlLlpf4huzxI1QchqhJv04Wd6PF82xOzPXbgcosdg7/tA7fr/3ZITQ4tUpGC3UxdbZH/i6Hx13RyBPZMwVIstdotSC3BG7J5KVtz5++vL56zelZlyZhCF3+J8Yg+A5Ozw37g5fYldozBWKs5L5aPj85F1W3nR4o7dm8HNs/gdDl7gNeRCHH5v0xyAPmuCXl0prKWGFX76HS9rfN//CnkhC3uUbr6n1Q3rjKb1xSG8cJtWuw4c6vFGi2Ga3j8+j/j3t3zFRaovmKWgOBd0SdEs0RtxOf8KPO7zR+Qc9wRidf/FPjEIwYocRyBMm5JZgWOxOn9MG3EVdpvbyB2YqT0HzhG/0z1+t9QVjmFT2bFNB3O5DHT7U7p1LKnuL7TdAHwqGJRiWaI447efkak80z4SLpUBzuNg6I9WuzYUthJj8LJ1zxwCSrVL3D0jlIal2SLWTKncTcnvChzm80fiqQd1/lCp3U+X9lNql1x5Hc7XvQ+85XGjzamQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f7874686aa2cec793d84636acf901482/e4706/P2P-Client_05.avif 230w", "/en/static/f7874686aa2cec793d84636acf901482/d1af7/P2P-Client_05.avif 460w", "/en/static/f7874686aa2cec793d84636acf901482/7f308/P2P-Client_05.avif 920w", "/en/static/f7874686aa2cec793d84636acf901482/4346e/P2P-Client_05.avif 962w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f7874686aa2cec793d84636acf901482/a0b58/P2P-Client_05.webp 230w", "/en/static/f7874686aa2cec793d84636acf901482/bc10c/P2P-Client_05.webp 460w", "/en/static/f7874686aa2cec793d84636acf901482/966d8/P2P-Client_05.webp 920w", "/en/static/f7874686aa2cec793d84636acf901482/c76c7/P2P-Client_05.webp 962w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f7874686aa2cec793d84636acf901482/81c8e/P2P-Client_05.png 230w", "/en/static/f7874686aa2cec793d84636acf901482/08a84/P2P-Client_05.png 460w", "/en/static/f7874686aa2cec793d84636acf901482/c0255/P2P-Client_05.png 920w", "/en/static/f7874686aa2cec793d84636acf901482/fbf08/P2P-Client_05.png 962w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f7874686aa2cec793d84636acf901482/c0255/P2P-Client_05.png",
              "alt": "P2P Client for your INSTAR IP Camera",
              "title": "P2P Client for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Type in your cameras UID and the password for the admin account.`}</p>
    <h3 {...{
      "id": "camera-view",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#camera-view",
        "aria-label": "camera view permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Camera View`}</h3>
    <p>{`Now go back to the camera view window and right-click your new camera to connect it. You can then drag-and-drop it into a channel and right-click the live-video to start using your camera.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a094b43ae848f01412e861c73e270ca7/024d6/P2P-Client_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAC8ElEQVQoz2Ow84vxjckKSsz3jkrXcQgMz2/zi8l2Co5wj8oITim18Iow94m1CchRt/TUtPXRsvXRtvPRtfc1dw8xdg5kMHf29Q+Pic/Kj80qUjRzTixuDUgs9g6LCo3Pj01udfOLcfKNN/XMVDSzUzFzVTe11zG3VzO00rOw1zC2ZvDJrgurX+Sb0h4VWxYcmmtkZGSkbaQhpysgoSggoSAqqyopoyaupCykpSQmpyEmpy4qqyYqpy4hryqlrMUQXjUppH7RkmV77h87e3r7oYu7Dj27eLO/soaBgYGRiYmBgYGJgYmVkY0BA7CyczAEF09wzuyau3H/u1cv79y6c+/evZ+/f81bsICBgYGZmZmRkZGZiZmdmZOBkYERCTAwMLBzcoM0u+f2TV697+mjxw/uPXzz5s3fP39nzpwBspOAzZwMwSUTXHJ6F20+9Prp07u377179+7fv79z585nYGLj5hXk5Obn4Rbi4xdh4+fh4OFHIG5eXmFxsOZskOZ3L54/evj406dP////X7ZiraiMlryaibyqkZKKsYyOvriBlrSqobSaAQTJquvLaxkzBBf3u+X0zly9++7tO6dPX7hy5crLly+7u7vhzgb5lZkZ09ksbBwMwSWTgipmhlfNjKmfF1zQKSgswsvDw8nJycjIBEFMTCwsbGyMoABjgiNogNmEZtmE5dhF5FpH5Bt6xDAys6DZwMjIxMzCimkzGwcXg4qxnbKps7Kxg6qZi5yuBRMLpmZGnJrVzJw1bHzVLD207f0VDW1VLdx07Hz0rd1ULVwV9K1ltU3VLVyNPKOVTRzVrTy1bX20bX00rL20bH3VrTwY1M1dVMxcNCzd1MzdVM2cg4t6QssmJ9TNiKqcFlIyIbRkQnT1tKiqaWFlk0JKJoaVTYqrnRFaNim0dHJwUS+DVWCqVXCGc2SuVXCGmW9CRPmkyKrpYRVTwiumRlVNi6mZEV4xNbJyakzN9IjKqRGVU8MrpoAZ0yLKJwEAvfTspOJCi9QAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a094b43ae848f01412e861c73e270ca7/e4706/P2P-Client_06.avif 230w", "/en/static/a094b43ae848f01412e861c73e270ca7/d1af7/P2P-Client_06.avif 460w", "/en/static/a094b43ae848f01412e861c73e270ca7/7f308/P2P-Client_06.avif 920w", "/en/static/a094b43ae848f01412e861c73e270ca7/7733b/P2P-Client_06.avif 961w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a094b43ae848f01412e861c73e270ca7/a0b58/P2P-Client_06.webp 230w", "/en/static/a094b43ae848f01412e861c73e270ca7/bc10c/P2P-Client_06.webp 460w", "/en/static/a094b43ae848f01412e861c73e270ca7/966d8/P2P-Client_06.webp 920w", "/en/static/a094b43ae848f01412e861c73e270ca7/85db8/P2P-Client_06.webp 961w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a094b43ae848f01412e861c73e270ca7/81c8e/P2P-Client_06.png 230w", "/en/static/a094b43ae848f01412e861c73e270ca7/08a84/P2P-Client_06.png 460w", "/en/static/a094b43ae848f01412e861c73e270ca7/c0255/P2P-Client_06.png 920w", "/en/static/a094b43ae848f01412e861c73e270ca7/024d6/P2P-Client_06.png 961w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a094b43ae848f01412e861c73e270ca7/c0255/P2P-Client_06.png",
              "alt": "P2P Client for your INSTAR IP Camera",
              "title": "P2P Client for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Go back the Camera View window and right-click the camera to connect it.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e3e86d11d925a2e4376ec685da99a44f/d9199/P2P-Client_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC80lEQVQoz2Ow8Aj1jckITMjxCE1St/EJz2v3jspyCApzjUgPTi419Qg18Yq18MtSMnVRsfRUs3BXt/TQtPIwcvLXs/NmMHXy8Q2Pj84oDMstU7TyTChsDojJ8gwI8QtLDYuucnQPsXUNN3ZPUbJ0VDN30zBz0LNw1DC20Ta1VTeyZvDIrC3omZedVl3qGhUfkJk/a2Px6nPl806XLTxdPP94+YJT9cuu2IWnccoKi8mqC8soC0krCcsoi8ooSShqMASW9uT1ryipn9UemrfR2PWcT/yCjeemHX44a//tmftvzzr8cPO9//4xxQwMDMzMLAxIgJWdk8EnvyegZIpvyWSPunmeGa2xoTZhUfbOgY4OPpbuwbb+UV6xGQH6BjpgzcyMMMDAwMDOxcPgm98TVDY1sGRycNlUv5SmmGSv2HSHzKKggpLErPyg0orU/IIYY31tBgYGJiYmZJvZOLgQmgPLpwVktje2NKblJdTUlTbWlk3s6e7s7mior3N2dmNgZ+XmE+Tk4Ycibj4+YQmo5oCSycEVM1yTa5UtTZT0jNW0LdV0LLQNbLUNbdW1zBQMjMT0NaVVDaXVDCBIVl1fTtOYwTe/O6h0SmDxpKCyaX7ZLaysbAxogJGRAdXBsADjYPArmhBSOTO4YnpI9RzfnDZWdnaweiY4YmJiYWFjBwcTQhAaYBaBaTahOfbhubYReYbuUYxMzBgWMzGzsGLazMbJzaCgZ6Vq5qJsbK9q7iqnZ8mI4UJGRkaYZkZ0zUrG9lp2fqrmbtr2AYqGtmpW7gYO/oY2HuqW7oqGtnI65lrWHiZeMSqmTpo23jp2vjp2Ppo23lp2fuqWHgzKJo5qFu4aVu5qFu6q5i6RNf3JDbNz2hck1s+OqZoSUzUlrXlufN3M6Kqp8XXTY6qnxdfNDC+fHF4xJbioh8HUJ94qKM0xItsiINXMJz60pD+0fHJwyYSQ0onh5ZMjKqaGlE4MLZsUUTElrGxSaNkkCDe0fHJIUQ8AlYPjaQWK2nAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e3e86d11d925a2e4376ec685da99a44f/e4706/P2P-Client_07.avif 230w", "/en/static/e3e86d11d925a2e4376ec685da99a44f/d1af7/P2P-Client_07.avif 460w", "/en/static/e3e86d11d925a2e4376ec685da99a44f/7f308/P2P-Client_07.avif 920w", "/en/static/e3e86d11d925a2e4376ec685da99a44f/542bc/P2P-Client_07.avif 960w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e3e86d11d925a2e4376ec685da99a44f/a0b58/P2P-Client_07.webp 230w", "/en/static/e3e86d11d925a2e4376ec685da99a44f/bc10c/P2P-Client_07.webp 460w", "/en/static/e3e86d11d925a2e4376ec685da99a44f/966d8/P2P-Client_07.webp 920w", "/en/static/e3e86d11d925a2e4376ec685da99a44f/e46b2/P2P-Client_07.webp 960w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e3e86d11d925a2e4376ec685da99a44f/81c8e/P2P-Client_07.png 230w", "/en/static/e3e86d11d925a2e4376ec685da99a44f/08a84/P2P-Client_07.png 460w", "/en/static/e3e86d11d925a2e4376ec685da99a44f/c0255/P2P-Client_07.png 920w", "/en/static/e3e86d11d925a2e4376ec685da99a44f/d9199/P2P-Client_07.png 960w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e3e86d11d925a2e4376ec685da99a44f/c0255/P2P-Client_07.png",
              "alt": "P2P Client for your INSTAR IP Camera",
              "title": "P2P Client for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Left-click and hold the camera to drop it into a channel.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/03bae46b5ab081023cb82c3564fccb29/024d6/P2P-Client_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADB0lEQVQoz2Ow8orwjkzzj8t2C0tWs/YJyW31jsqyDwxzDU8PSioxdQ819oy28MtSMnVVtfBQtfBQs/TQtPI0dg4wcPBlMHfx9Q6Li88oSMwqVDV3ScxrCIzN8fQP9g9LDYuucnQPtnUNM3ZPVbJwVDV30zBz1LNw0DC21rWw1zSxZfDOqS/smBVV1Omc0uAaX5m1YFfx8hOlc0+Vzj9ZNPdY6fwTdUuvOERmcMoJi8lpiMiqCMsoi8iqiMkqSyhqMIRU9Of3LcvsWBJUMcM/r3vW7sdzjj6ZduDejP23Zxy4M/vI420P/wcnVjAwMDCzsDAgAVZ2DoaAgj7/4in+xZOCyqd7pTdFJfpGpvn5Rnr7hnv4RbgGRHnFZgYYGOmCNDMzM8IAAwMDGyc3Q0BhX2DZ1MCSyUEV073Sm+Pi3A8e3n792o179+7evnuzvCo9Lz/GDKyZiYkJ1WZOhObA8uk+mS0tLS3Xb1z7+P7j79+/v3z+0tPb1VBf5+rmwcDOys0nyMnDD0XcfHzCElDNASWTgytmuCbXKVua9cyYtWPL9j27d+/es8fY0llVw0TBwFhMX0tazVBazQCCZNX15TWNGQIKewNLpgQWTwoqm+aX3crKysbAyMjKxsbCwsLGxgZ1IqqDIYCFjYMhqGRScOXM4PLpIVWz/XI72Dm5wFKMEBWMjIxMTCwsbOzgYGKCIwYGBnZOHgabkEzr0Gy7iFzbiDxDj2hmFlY0GxgZmTAFQaHNwcWgYmynbOqkbOygauYir2vJxMyCoZkRppkRXbOamZOmja+apbu2vb+ioa26tYeBU6CRrae6laeSkZ28roWWtYepd5yquYuWra+ug7+OvZ+mra+2fYCGtReDhoWrmrmbppWHuqW7irlTZE1/csOc7PYFSQ2zY6qnxVRNTW+Zm1A/K6Z6WkLdjNia6fH1s8IrpoRXTA0t6WewCki2DExzisi2DEw19Y4LLe4LLZsUXDIhpHRiWPmkiIopwSUTQ0snRlRMDi2bGFo6KQTMDS2bFFrSBwAGIPcD69zTpAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/03bae46b5ab081023cb82c3564fccb29/e4706/P2P-Client_08.avif 230w", "/en/static/03bae46b5ab081023cb82c3564fccb29/d1af7/P2P-Client_08.avif 460w", "/en/static/03bae46b5ab081023cb82c3564fccb29/7f308/P2P-Client_08.avif 920w", "/en/static/03bae46b5ab081023cb82c3564fccb29/7733b/P2P-Client_08.avif 961w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/03bae46b5ab081023cb82c3564fccb29/a0b58/P2P-Client_08.webp 230w", "/en/static/03bae46b5ab081023cb82c3564fccb29/bc10c/P2P-Client_08.webp 460w", "/en/static/03bae46b5ab081023cb82c3564fccb29/966d8/P2P-Client_08.webp 920w", "/en/static/03bae46b5ab081023cb82c3564fccb29/85db8/P2P-Client_08.webp 961w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/03bae46b5ab081023cb82c3564fccb29/81c8e/P2P-Client_08.png 230w", "/en/static/03bae46b5ab081023cb82c3564fccb29/08a84/P2P-Client_08.png 460w", "/en/static/03bae46b5ab081023cb82c3564fccb29/c0255/P2P-Client_08.png 920w", "/en/static/03bae46b5ab081023cb82c3564fccb29/024d6/P2P-Client_08.png 961w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/03bae46b5ab081023cb82c3564fccb29/c0255/P2P-Client_08.png",
              "alt": "P2P Client for your INSTAR IP Camera",
              "title": "P2P Client for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can right-click the video to start using your camera.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      